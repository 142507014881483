import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

// PERFECT SCROLLBAR
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
Vue.use(PerfectScrollbar)

// PAGE TITLE 
import VuePageTitle from 'vue-page-title'
Vue.use(VuePageTitle, {
  // prefix: 'My App - ',
  suffix: '- DEHN Selectie Matrix'
});

// LINNOX MIXINS
import liMixin from './mixins/liMixin';
Vue.mixin(liMixin)

// LINNOX COMPONENTS
// LOGO
import liLogo from './components/structure/logo';
Vue.component('liLogo', liLogo);
// NAVIGATION
import liNav from './components/structure/nav';
Vue.component('liNav', liNav);
// INFO MODAL
import liInfoModal from './components/infoModal/infoModal';
Vue.component('liInfoModal', liInfoModal);
import liInfoModalBtn from './components/infoModal/infoModalBtn';
Vue.component('liInfoModalBtn', liInfoModalBtn);
// CONFIRM MODALS
import liConfirmGrid from './components/infoModal/liConfirmGrid';
Vue.component('liConfirmGrid', liConfirmGrid);
import liConfirmExternal from './components/infoModal/liConfirmExternal';
Vue.component('liConfirmExternal', liConfirmExternal);
  
// FOOTER
import liFooter from './components/structure/footer';
Vue.component('liFooter', liFooter);

// MATRIX
import liAddSubdivider from './components/matrix/liAddSubdivider';
Vue.component('liAddSubdivider', liAddSubdivider);
import liAddCriticalEndGroup from './components/matrix/liAddCriticalEndGroup';
Vue.component('liAddCriticalEndGroup', liAddCriticalEndGroup);
import liDownloadPDF from './components/matrix/liDownloadPDF';
Vue.component('liDownloadPDF', liDownloadPDF);

// PROJECT
import liSelected from './components/projects/editItem';
Vue.component('liSelected', liSelected);

// Custom filters
import './filters/filters';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
