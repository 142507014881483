<template>
  <v-btn icon class="my-auto" :class="styleClasses" :x-small="size == 'x-small'" :small="size == 'small'" @click.prevent="openInfoModal(infoIdentifier)">
    <v-icon> {{ icon }}</v-icon>
  </v-btn>
</template>
<script>
export default {
  name: "liInfoModalBtn",
  props: {
    infoIdentifier: null,
    styleClasses: {
      default: "ml-3",
      required: false,
      type: String,
    },
    icon: {
      default: "mdi-information-outline",
      required: false,
      type: String,
    },
    size: {
      default: "x-small",
      required: false,
      type: String,
    },
  },
};
</script>
