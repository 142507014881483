import Vue from 'vue';
import Vuex from 'vuex';

import {
    auth
} from './modules/auth.module';
import {
    liDehnProject
} from './modules/liDenhProject.module';


Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth:auth,
        liDehnProject: liDehnProject
    }
});
