<template>
  <!-- ### √ CRITICAL END GROUP -->
  <div v-if="selected != null && sdMatrix != null && ceMatrix != null && products != null" class="border-bottom pa-3">
    <!-- {{ selectedParent }} -->
    <!-- LOCATION -->
    <template v-if="rule_NeedLocation(newProjectStore)">
      <div class="w-100 white pt-3 px-3 mt-3" v-if="selected.type != 0">
        <div class="d-flex w-100 border-bottom pb-3">
          <label v-html="appConfig.texts.project.form.external.labelCe" />
          <liInfoModalBtn infoIdentifier="location" styleClasses="ml-auto mt-0 mr-0" />
        </div>
        <v-radio-group dense v-model="selected.external" row required @change="checkDivider(selected)">
          <template v-for="(option, index) in appConfig.texts.project.form.external.options">
            <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10" @input="checkDivider(selected)"></v-radio>
          </template>
        </v-radio-group>
      </div>
    </template>

    <!-- CABLE LENGTH -->
    <div class="w-100 white pt-3 px-3 mt-3">
      <div class="d-flex w-100 border-bottom pb-3">
        <label v-html="appConfig.texts.project.form.cableLength.labelMatrixCe" />
        <liInfoModalBtn infoIdentifier="cableLength" styleClasses="ml-auto mt-0 mr-0" />
      </div>
      <v-radio-group dense v-model="selected.cableLength" row required @change="setCableLength(selected)">
        <template v-for="(option, index) in appConfig.texts.project.form.cableLength.options">
          <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10"></v-radio>
        </template>
      </v-radio-group>
    </div>

    <!-- LPL ZONE -->
    <template v-if="rule_NeedLpZone(selected, newProjectStore)">
      <div class="w-100 white pt-3 px-3 mt-3">
        <div class="d-flex w-100 border-bottom pb-3">
          <label v-html="appConfig.texts.project.form.lplZone.labelSd" />
          <liInfoModalBtn infoIdentifier="LPL" styleClasses="ml-auto mt-0 mr-0" />
        </div>
        <v-radio-group dense v-model="selected.lplZone" row required @change="checkDivider(selected)">
          <template v-for="(option, index) in appConfig.texts.project.form.lplZone.options">
            <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10"></v-radio>
          </template>
        </v-radio-group>
      </div>
    </template>

    <!-- NO PRODUCT REQUIRED CABLE LENGTH < 10 -->
    <div v-if="rule_ProductNotRequired(selected)" class="mt-3 w-100 d-flex px-3 pt-3 white">
      <v-alert dense text type="success" icon="mdi-shield-check" class="rounded-md"><span v-html="appConfig.texts.project.form.cableLength.explaination" /></v-alert>
    </div>

    <!-- PRODUCT OPTIONS -->
    <template v-if="selected.cableLength != '' && !rule_ProductNotRequired(selected)">
      <!-- GRID -->
      <div class="w-100 white pt-3 px-3 mt-3" v-if="newProjectStore.grid == 'TN-C'">
        <div class="d-flex w-100 border-bottom pb-3">
          <label v-html="appConfig.texts.project.form.grid.label" />
          <liInfoModalBtn infoIdentifier="grid" styleClasses="ml-auto mt-0 mr-0" />
        </div>
        <v-radio-group dense v-model="selected.grid" row :rules="defaultRules" required @change="checkDivider(selected)">
          <template v-for="(option, index) in appConfig.texts.project.form.grid.options">
            <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10"></v-radio>
          </template>
        </v-radio-group>
      </div>
      <div class="white mt-3">
        <!-- AMPERE -->
        <div v-if="ampereOptions != null" class="w-100 pt-3 px-3" :class="{ liDisabled: ampereOptions == null }">
          <div class="d-flex w-100 mt-5">
            <label>{{ appConfig.texts.project.form.amp.label }} </label>
            <liInfoModalBtn infoIdentifier="ampereSD" styleClasses="ml-auto mt-0 mr-0" />
          </div>
          <v-autocomplete dense :placeholder="appConfig.texts.project.form.amp.placeholder" :items="ampereOptions" v-model="selected.ampere" item-value="value" item-text="name" @input="checkDivider(selected)" />
        </div>
        <!-- KORTSLUITVASHEID -->
        <div class="w-100 px-3" :class="{ liDisabled: shortCircuitResistanceOptions == null }">
          <div class="d-flex w-100 mt-5">
            <label v-html="appConfig.texts.project.form.shortCircuitResistanceCe.label" />
            <liInfoModalBtn infoIdentifier="shortcircuit" styleClasses="ml-auto mt-0 mr-0" />
          </div>
          <v-select :placeholder="appConfig.texts.project.form.shortCircuitResistanceCe.placeholder" :items="appConfig.texts.project.form.shortCircuitResistanceCe.options" v-model="selected.shortCircuitResistance" item-value="value" item-text="name" @input="checkDivider(selected)" dense />
        </div>
        <!-- PHASE -->
        <template v-if="rule_NeedPhaseSd(selected)">
          <div class="w-100 px-3" :class="{ liDisabled: shortCircuitResistanceOptions == null }">
            <div class="d-flex w-100 mt-5">
              <label>{{ appConfig.texts.project.form.phase.label }}</label>
              <liInfoModalBtn infoIdentifier="phase" styleClasses="ml-auto mt-0 mr-0" />
            </div>
            <v-select dense required :disabled="shortCircuitResistanceOptions == null" :placeholder="appConfig.texts.project.form.phase.placeholder" :items="appConfig.texts.project.form.phase.options" v-model="selected.phase" item-value="value" item-text="name" @input="checkDivider(selected)" />
          </div>
        </template>
        <!-- INTEGREATE FUSE -->
        <div class="w-100 px-3" :class="{ liDisabled: shortCircuitResistanceOptions == null }">
          <div class="d-flex w-100 mt-5">
            <label>{{ appConfig.texts.project.form.preAmp.label }}</label>
            <liInfoModalBtn infoIdentifier="integratedFuseSD" styleClasses="ml-auto mt-0 mr-0" />
          </div>
          <v-select :disabled="shortCircuitResistanceOptions == null" dense :required="!selected.preAmp" :placeholder="appConfig.texts.project.form.preAmp.placeholder" :items="appConfig.texts.project.form.preAmp.options" v-model="selected.preAmp" item-value="value" item-text="name" @input="checkDivider(selected)"></v-select>
        </div>
      </div>
    </template>

    <!-- √√√ SELECTED PRODUCT -->
    <div class="w-100 px-3 pt-3 white" v-if="rule_showProductCE(selected, newProjectStore)">
      <label v-html="appConfig.texts.project.form.selectProduct.label" />
      <v-autocomplete v-model="selected.product" :items="products" :filter="searchProduct" :placeholder="appConfig.texts.project.form.selectProduct.placeholder" chips dense color="blue-grey lighten-2" item-value="identifier" item-text="name">
        <template v-slot:selection="data">
          <v-chip style="height: 45px; padding-right: 25px; margin-bottom: 5px; border-radius: 45px" class="body-3 grey lighten-3" v-bind="data.attrs" :input-value="data.selected">
            <img :src="`${apiPublicUrl}${data.item.thumbnailpath}`" style="width: 40px" class="mr-3" />
            {{ data.item.name }} <span class="mx-3">{{ data.item.article_number }}</span>
          </v-chip>
        </template>
        <template v-slot:item="data">
          <v-list-item-avatar>
            <img :src="`${apiPublicUrl}${data.item.thumbnailpath}`" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name"></v-list-item-title>
            <v-list-item-subtitle v-html="data.item.article_number"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
      <!-- MORE THEN ONE PRODUCT FOUND-->
      <div v-if="'productOptions' in selected && selected.productOptions != ''" class="w-100 d-flex white">
        <v-alert dense text type="info" class="rounded-md w-100">
          <div class="w-100" v-html="appConfig.texts.project.moreProductsFound" />
          <div v-for="(option, i) in selected.productOptions" :key="i">
            <template v-if="i != 0">
              {{ `${product(option).name} - ${product(option).article_number}` }}
            </template>
          </div>
        </v-alert>
      </div>
      <!-- NO PRODUCT FOUND -->
      <div v-if="'noProductReason' in selected && selected.noProductReason != ''" class="w-100 d-flex white">
        <v-alert dense text type="error" icon="mdi-flash-triangle" class="rounded-md w-100"><span v-html="selected.noProductReason" /></v-alert>
      </div>
      <!-- PRODUCT NOTE-->
      <div v-if="'productNote' in selected && selected.productNote != null && selected.productNote != ''" class="w-100 d-flex white">
        <v-alert dense text type="warning" icon="mdi-flash-triangle" class="rounded-md w-100"><span v-html="selected.productNote" /></v-alert>
      </div>
      <v-btn depressed class="mb-3" color="primary" :block="isMobileApp" @click.prevent="setDivider()" :disabled="selected.noProductReason != ''">
        {{ appConfig.texts.products.accordionButtons.mainDivider.btnSelect }}
      </v-btn>
    </div>
  </div>
</template>

<script>
const API_URL = process.env.VUE_APP_API_URL;
export default {
  title: "",
  name: "liAddCriticalEndGroup",
  data() {
    return {
      apiPublicUrl: API_URL,
      valid: true,
      shortCircuitResistanceOptions: null,
      ampereOptions: null,
      // itemKv: null,
      // itemKvNb: true,
      // itemKvError: "",
      defaultRules: [(v) => !!v || this.appConfig.texts.projects.required],
    };
  },
  props: {
    selected: {
      type: Object,
      required: true,
      default: null,
    },
    selectedParent: {
      type: Object,
      required: true,
      default: null,
    },
    selectedDepth: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  computed: {
    sdMatrix() {
      return this.$store.getters["liDehnProject/getCollection"](`matrix_sd`);
    },
    ceMatrix() {
      return this.$store.getters["liDehnProject/getCollection"](`matrix_ce`);
    },
  },
  mounted() {
    this.setupCeAmpereOptions();
  },
  methods: {
    setDivider() {
      this.$store.dispatch("liDehnProject/SET_CRITENDGROUP_NEW", this.selected);
      this.$emit("addDivider");
    },
    setCableLength(selectedItem) {
      if (selectedItem.cableLength == "<10") {
        this.$set(selectedItem, "ampere", this.selectedParent.ampere);
        this.$set(selectedItem, "shortCircuitResistance", this.selectedParent.shortCircuitResistance);
        this.$set(selectedItem, "grid", this.selectedParent.grid);
        this.$set(selectedItem, "phase", "");
        this.$set(selectedItem, "lplZone", "");
        this.$set(selectedItem, "product", "");
        this.$set(selectedItem, "productNote", "");
        this.$set(selectedItem, "noProductReason", "");
        this.$set(selectedItem, "productOptions", []);
        this.checkDivider(selectedItem);
      } else {
        this.checkDivider(selectedItem);
      }
    },
    // checkKv(selected) {
    //   this.itemKvError = "";
    //   if (this.itemKv != null && this.itemKv != undefined && this.itemKv != "" && this.itemKv.startsWith("0")) {
    //     this.itemKv = this.itemKv.substring(1);
    //     selected.shortCircuitResistance = this.itemKv;
    //   }
    //   if (Number(this.itemKv) >= Number(this.selectedParent.shortCircuitResistance)) {
    //     // selected.shortCircuitResistance = this.selectedParent.shortCircuitResistance;
    //     this.itemKv = this.selectedParent.shortCircuitResistance - 1;
    //     // selected.shortCircuitResistance = this.itemKv;

    //     this.itemKvError = this.appConfig.texts.project.form.itemKvError;
    //   }
    //   if (this.itemKvNb == true) {
    //     if (this.itemKv == null) {
    //       selected.shortCircuitResistance = "";
    //     } else if (this.itemKv <= 6) {
    //       selected.shortCircuitResistance = "6";
    //     } else if (this.itemKv > 6 && this.itemKv <= 25) {
    //       selected.shortCircuitResistance = "25";
    //     } else if (this.itemKv > 25 && this.itemKv <= 50) {
    //       selected.shortCircuitResistance = "50";
    //     } else if (this.itemKv > 50) {
    //       selected.shortCircuitResistance = "100";
    //     }
    //   } else if (this.itemKvNb == false) {
    //     selected.shortCircuitResistance = "nb";
    //   }
    //   this.checkDivider(this.selected);
    // },
    checkDivider(selectedItem) {
      this.$set(selectedItem, "product", "");
      this.$set(selectedItem, "productNote", "");
      this.$set(selectedItem, "noProductReason", "");
      this.$set(selectedItem, "productOptions", []);

      const ex = this.newProjectStore.external == 0 ? "ex-nee" : this.newProjectStore.external == 2 ? "ex-nb" : "ex-ja";
      const _in = selectedItem.external == 0 ? "binnen" : "buiten";
      const lpl = this.newProjectStore.lpl == 0 ? "lpl-nb" : this.newProjectStore.lpl == 1 ? "lpl-I" : this.newProjectStore.lpl == 2 ? "lpl-II" : this.newProjectStore.lpl == 3 ? "lpl-III" : "lpl-IV";
      const lpz = selectedItem.lplZone == "a" ? "lpz0a" : selectedItem.lplZone == "b" ? "lpz0b" : "lpznb";

      // console.log("PROJECT EX LP = ", this.newProjectStore.external);
      // console.log("TYPE = ", selectedItem.type);
      // console.log("Aansluitwaarde = ", `aw-${selectedItem.ampere}`);
      // console.log("Kortsluitingsvastheid = ", `kv-${selectedItem.shortCircuitResistance}`);
      // console.log("Integrated fuse = ", `if-${selectedItem.preAmp}`);
      // console.log("Phase = ", selectedItem.phase);
      // console.log("GRID = ", selectedItem.grid);
      // console.log("BINNEN / BUITEN = ", _in);
      // console.log("ZONE = ", lpz);

      //* SHORT CIRCUIT RESISTANCE RULES *\\
      let shortCircuitResistanceOptions = [];
      if (selectedItem.ampere > 32 && selectedItem.ampere <= 800) {
        this.appConfig.texts.project.form.shortCircuitResistance.options.forEach((option) => {
          if (option.value > 6 && option.value < 100) {
            shortCircuitResistanceOptions.push(option);
          }
        });
        if (selectedItem.shortCircuitResistance == 6) {
          this.$set(selectedItem, "shortCircuitResistance", "");
          this.itemKv = "";
          this.itemKvError = this.appConfig.texts.project.form.itemKvErrorCantBeAnd;
        }
      } else if (selectedItem.ampere >= 800) {
        this.appConfig.texts.project.form.shortCircuitResistance.options.forEach((option) => {
          if (option.value > 6) {
            shortCircuitResistanceOptions.push(option);
          }
        });
        if (selectedItem.shortCircuitResistance == 6) {
          this.$set(selectedItem, "shortCircuitResistance", "");
          this.itemKv = "";
          this.itemKvError = this.appConfig.texts.project.form.itemKvErrorCantBe;
        }
      } else if (selectedItem.ampere <= 32) {
        this.appConfig.texts.project.form.shortCircuitResistance.options.forEach((option) => {
          shortCircuitResistanceOptions.push(option);
        });
      }
      this.shortCircuitResistanceOptions = [...shortCircuitResistanceOptions, this.appConfig.texts.project.form.shortCircuitResistance.notKnown];

      //* PHASE RULES *\\
      if (this.rule_SetPhase(selectedItem)) {
        this.$set(selectedItem, "phase", "3FN");
        console.log("rule_SetPhase has changed the phase to = ", selectedItem.phase);
      }

      let products = "";
      //* SELECT PRODUCT *\\
      if (selectedItem.grid != "" && selectedItem.ampere != "" && selectedItem.shortCircuitResistance != "" && selectedItem.preAmp != "") {
        // EXTERNAL LIGHTNING PROTECTION OR NOT
        if (this.newProjectStore.external == 0 || this.newProjectStore.external == 2) {
          // * FOR  SUBDIVIDER *\\
          if (selectedItem.type == 1) {
            products = this.sdMatrix.filter((item) => {
              if (_in == "binnen") {
                if (item[ex] == 1 && item[`aw-${selectedItem.ampere}`] == 1 && item[`kv-${selectedItem.shortCircuitResistance}`] == 1 && item[selectedItem.phase] == 1 && item[`if-${selectedItem.preAmp}`] == 1) {
                  return item;
                }
              } else {
                if (item[lpz] == 1 && item[ex] == 1 && item[`aw-${selectedItem.ampere}`] == 1 && item[`kv-${selectedItem.shortCircuitResistance}`] == 1 && item[selectedItem.phase] == 1 && item[`if-${selectedItem.preAmp}`] == 1) {
                  return item;
                }
              }
            });
          }
          // * FOR  CRITICAL END GROUPS *\\
          else if (selectedItem.type == 2) {
            products = this.ceMatrix.filter((item) => {
              if (_in == "binnen") {
                if (item[ex] == 1 && item[`aw-${selectedItem.ampere}`] == 1 && item[`kv-${selectedItem.shortCircuitResistance}`] == 1) {
                  return item;
                }
              } else {
                if (item[lpz] == 1 && item[ex] == 1 && item[`aw-${selectedItem.ampere}`] == 1 && item[`kv-${selectedItem.shortCircuitResistance}`] == 1) {
                  return item;
                }
              }
            });
          }
        } else {
          // * FOR  SUBDIVIDER *\\
          if (selectedItem.type == 1) {
            products = this.sdMatrix.filter((item) => {
              if (_in == "binnen") {
                if (item[ex] == 1 && item[lpl] == 1 && item[_in] == 1 && item[`aw-${selectedItem.ampere}`] == 1 && item[`kv-${selectedItem.shortCircuitResistance}`] == 1 && item[selectedItem.phase] == 1 && item[`if-${selectedItem.preAmp}`] == 1) {
                  return item;
                }
              } else {
                if (item[lpz] == 1 && item[ex] == 1 && item[lpl] == 1 && item[_in] == 1 && item[`aw-${selectedItem.ampere}`] == 1 && item[`kv-${selectedItem.shortCircuitResistance}`] == 1 && item[selectedItem.phase] == 1 && item[`if-${selectedItem.preAmp}`] == 1) {
                  return item;
                }
              }
            });
          }
          // * FOR  CRITICAL END GROUPS *\\
          else if (selectedItem.type == 2) {
            products = this.ceMatrix.filter((item) => {
              if (_in == "binnen") {
                if (item[ex] == 1 && item[lpl] == 1 && item[lpz] == 1 && item[_in] && item[`aw-${selectedItem.ampere}`] == 1 && item[`kv-${selectedItem.shortCircuitResistance}`] == 1) {
                  return item;
                }
              } else {
                if (item[lpz] == 1 && item[ex] == 1 && item[lpl] == 1 && item[lpz] == 1 && item[_in] && item[`aw-${selectedItem.ampere}`] == 1 && item[`kv-${selectedItem.shortCircuitResistance}`] == 1) {
                  return item;
                }
              }
            });
          }
        }
        console.log(products);
        if (products != undefined && products.length > 0) {
          if (products[0] != undefined && products[0][selectedItem.grid] != null) {
            this.$set(selectedItem, "product", products[0][selectedItem.grid]);
            this.$set(selectedItem, "productNote", products[0].Opmerkingen);
            this.$set(selectedItem, "noProductReason", "");
            if (products.length > 1) {
              let productIdentifiers = [];
              products.forEach((product) => {
                productIdentifiers.push(product[selectedItem.grid]);
              });
              this.$set(selectedItem, "productOptions", productIdentifiers);
            }
          } else {
            this.$set(selectedItem, "productOptions", []);
            this.$set(selectedItem, "product", "");
            this.$set(selectedItem, "productNote", "");
            this.$set(selectedItem, "noProductReason", `${this.appConfig.texts.project.form.foundButNoArticleNo} <b>${products[0].Name}</b> - <b>${selectedItem.grid}</b>`);
          }
        } else {
          this.$set(selectedItem, "productOptions", []);
          this.$set(selectedItem, "product", "");
          this.$set(selectedItem, "productNote", "");
          this.$set(selectedItem, "noProductReason", `${this.appConfig.texts.project.form.noProductReason} <br><br><span><b>Aansluitwaarde:</b> ${selectedItem.ampere} A<br><b>Kortsluitvastheid:</b> ${selectedItem.shortCircuitResistance} kArms<br><b>Fase:</b> ${selectedItem.phase}<br><b>Voorzekering:</b> ${selectedItem.preAmp}</span> `);
        }
        // console.log(products);
      } else {
        console.log("not complete item");
        this.$set(selectedItem, "product", "");
        this.$set(selectedItem, "productNote", "");
        this.$set(selectedItem, "noProductReason", "");
        this.$set(selectedItem, "productOptions", []);
      }
    },
    setupCeAmpereOptions() {
      let index = this.appConfig.texts.project.form.amp.options[`ceSpecific`].findIndex((option) => option.value == this.selectedParent.ampere);
      if (index == 0) {
        index = 1;
      } else if (index == 1) {
        index = 2;
      } else {
        index = index - 1;
      }
      let ampOptions = this.appConfig.texts.project.form.amp.options[`ceSpecific`].slice(0, index);
      this.ampereOptions = ampOptions;
    },
    // SEARCH PRODUCTS // => in MIXIN
    // PRODUCT // => in MIXIN

    // RULES // => in MIXIN
  },
};
</script>
