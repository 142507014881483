import liApiService from "../services/liApi.service";
export const liDehnProject = {
  namespaced: true,
  state: {
    general: {
      language: "NL",
    },
    collections: {},
    project: null,
    newProjectStore: null,
    custom: null,
    questions: {
      "00r": null,
      "01i": null,
      "02u": null,
      "03z": null,
      "04d": null,
      "05e": null,
      "06p": null,
    },
    projectDialog: false,
  },
  actions: {
    GET_COLLECTION({ commit }, _data) {
      return liApiService.getCollection(_data.request).then(
        (response) => {
          commit("setCollection", {
            collection: response.payload,
            collectionName: _data.collectionName,
          });
          return Promise.resolve(response.payload);
        },
        (error) => {
          console.debug(error);
        }
      );
    },
    CREATE_COLLECTION_ITEM({ commit }, _data) {
      const obj = {
        object: _data.object ? _data.object : "",
        item: _data.item ? _data.item : "",
      };
      if (obj.object != "") {
        // Set created_by field
        obj.item.created_by = _data.user;
        return liApiService.createCollectionItem(obj).then(
          (response) => {
            commit("emptyFn");
            return Promise.resolve(response.payload);
          },
          (error) => {
            return Promise.error(error.message);
          }
        );
      }
    },
    REMOVE_COLLECTION_ITEM({ commit }, _data) {
      const obj = {
        object: _data.object ? _data.object : "",
        item: _data.item ? _data.item : "",
      };
      if (obj.object != "") {
        // Set deleted_by field
        obj.item.deleted_by = _data.user;
        return liApiService.removeCollectionItem(obj).then(
          (response) => {
            commit("emptyFn");
            return Promise.resolve(response.payload);
          },
          (error) => {
            return Promise.error(error.message);
          }
        );
      }
    },
    UPDATE_COLLECTION_ITEM({ commit }, _data) {
      const obj = {
        object: _data.object ? _data.object : "",
        item: _data.item ? _data.item : "",
      };
      if (obj.object != "") {
        // Set updated_by field
        obj.item.updated_by = _data.user;
        return liApiService.updateCollectionItem(obj).then(
          (response) => {
            commit("emptyFn");
            return Promise.resolve(response.payload);
          },
          (error) => {
            return Promise.error(error.message);
          }
        );
      }
    },
    MOVE_COLLECTION_ITEM({ commit }, _data) {
      const obj = {
        object: _data.object ? _data.object : "",
        tree: _data.tree ? _data.tree : "",
      };

      if (obj.object != "") {
        return liApiService.moveCollectionItem(obj).then(
          (response) => {
            commit("emptyFn");
            return Promise.resolve(response.payload);
          },
          (error) => {
            return Promise.error(error.message);
          }
        );
      }
    },
    GET_QUESTIONS_R({ commit }) {
      return liApiService.getQuestionsR().then(
        (response) => {
          commit("setQuestionsR", response);
          return Promise.resolve();
        },
        (error) => {
          console.log(error);
        }
      );
    },
    GET_QUESTIONS_U({ commit }) {
      return liApiService.getQuestionsU().then(
        (response) => {
          commit("setQuestionsU", response);
          return Promise.resolve();
        },
        (error) => {
          console.log(error);
        }
      );
    },
    GET_QUESTIONS_I({ commit }) {
      return liApiService.getQuestionsI().then(
        (response) => {
          commit("setQuestionsI", response);
          return Promise.resolve();
        },
        (error) => {
          console.log(error);
        }
      );
    },
    SET_PROJECT({ commit }) {
      commit("setProject");
      return Promise.resolve();
    },
    RESTORE_PROJECT({ commit }, obj) {
      commit("restoreProject", obj);
      return Promise.resolve();
    },
    SET_NAME({ commit }, name) {
      commit("setName", name);
      return Promise.resolve();
    },
    SET_TYPE({ commit }, type) {
      commit("setType", type);
      return Promise.resolve();
    },
    SET_GRID({ commit }, grid) {
      commit("setGrid", grid);
      return Promise.resolve();
    },
    SET_MAIN_DISTRIBUTOR({ commit }, distributor) {
      commit("setMainDistributor", distributor);
      return Promise.resolve();
    },
    REMOVE_MAIN_DISTRIBUTOR({ commit }) {
      commit("removeMainDistributor");
      return Promise.resolve();
    },
    SET_DIVIDER({ commit }, divider) {
      commit("setDivider", divider);
      return Promise.resolve();
    },
    REMOVE_DIVIDER({ commit }, index) {
      commit("removeDivider", index);
      return Promise.resolve();
    },
    SET_CRITENDGROUP({ commit }, product) {
      commit("setCritEndGroup", product);
      return Promise.resolve();
    },
    REMOVE_CRIT_END_GROUP({ commit }, index) {
      commit("removeCritEndGroup", index);
      return Promise.resolve();
    },
    UPDATE_LOCALSTORAGE({ commit }) {
      commit("updateLocalStorage");
    },
    SET_CUSTOM_VALUE({ commit }, obj) {
      commit("setCustomValue", obj);
    },
    SET_PROJECT_DIALOG({ commit }, bool) {
      commit("setProjectDialog", bool);
    },
    // NEW
    SET_NEW_PROJECT({ commit }) {
      commit("setNewProject");
      return Promise.resolve();
    },
    SET_MAIN_DIVIDER({ commit }, divider) {
      commit("setMainDivider", divider);
      return Promise.resolve();
    },
    REMOVE_MAIN_DIVIDER({ commit }) {
      commit("removeMainDivider");
      return Promise.resolve();
    },
    SET_NAME_NEW({ commit }, name) {
      commit("setNameNew", name);
      return Promise.resolve();
    },
    SET_TYPE_NEW({ commit }, type) {
      commit("setTypeNew", type);
      return Promise.resolve();
    },
    SET_GRID_NEW({ commit }, grid) {
      commit("setGridNew", grid);
      return Promise.resolve();
    },
    SET_EXTERNAL_NEW({ commit }, external) {
      commit("setExternalNew", external);
      return Promise.resolve();
    },
    SET_LOCATION_NEW({ commit }, location) {
      commit("setLocationNew", location);
      return Promise.resolve();
    },
    SET_LPL_NEW({ commit }, lpl) {
      commit("setLPLNew", lpl);
      return Promise.resolve();
    },
    SET_ITEMTYPE_NEW({ commit }, type) {
      commit("setItemTypeNew", type);
      return Promise.resolve();
    },
    RESTORE_PROJECT_NEW({ commit }, obj) {
      commit("restoreProjectNew", obj);
      return Promise.resolve();
    },
    SET_DIVIDER_NEW({ commit }, divider) {
      commit("setDividerNew", divider);
      return Promise.resolve();
    },
    REMOVE_DIVIDER_NEW({ commit }, index) {
      commit("removeDividerNew", index);
      return Promise.resolve();
    },
    SET_CRITENDGROUP_NEW({ commit }, product) {
      commit("setCritEndGroupNew", product);
      return Promise.resolve();
    },
    REMOVE_CRIT_END_GROUP_NEW({ commit }, index) {
      commit("removeCritEndGroupNew", index);
      return Promise.resolve();
    },
    UPDATE_LOCALSTORAGE_NEW({ commit }) {
      commit("updateLocalStorageNew");
    },
  },
  mutations: {
    emptyFn: () => {
      return true;
    },
    setCollection(state, _data) {
      state.collections = {
        ...state.collections,
        [_data.collectionName]: _data.collection,
      };
    },
    setProjectDialog(state, bool) {
      state.projectDialog = bool;
    },
    setCustomValue(state, obj) {
      // console.log(state,obj)
      state.custom[obj.path] = obj.value;
      localStorage.setItem("liProjectDehnCustom", JSON.stringify(state.custom));
    },
    setQuestionsR(state, questions) {
      state.questions["00r"] = questions;
    },
    setQuestionsI(state, questions) {
      state.questions["01i"] = questions;
      state.questions["03z"] = questions;
      state.questions["04d"] = questions;
      state.questions["05e"] = questions;
      state.questions["06p"] = questions;
    },
    setQuestionsU(state, questions) {
      state.questions["02u"] = questions;
    },
    updateLocalStorage(state) {
      localStorage.setItem("liProjectDehn", JSON.stringify(state.project));
    },
    setProject(state) {
      state.project = {
        name: "",
        type: "",
        grid: "",
        main: {
          articleNo: null,
          name: "",
          alertText: "",
        },
        dividers: [],
        critEndGroups: [],
      };
      state.custom = {};
      localStorage.setItem("liProjectDehn", JSON.stringify(state.project));
      localStorage.setItem("liProjectDehnCustom", JSON.stringify(state.custom));
      console.log("setProject");
    },
    removeMainDistributor(state) {
      state.project = {
        name: state.project.name,
        type: state.project.type,
        grid: state.project.grid,
        main: {
          articleNo: null,
          name: "",
          alertText: "",
        },
        dividers: [],
        critEndGroups: [],
      };
      localStorage.setItem("liProjectDehn", JSON.stringify(state.project));
    },
    restoreProject: (state, obj) => {
      state.project = obj.project;
      state.custom = obj.custom;
      if (obj.project.type != "" && obj.project.main.articleNo != null && obj.project.main.articleNo != "") {
        state.projectDialog = true;
      }
    },
    setType: (state, type) => {
      state.project.type = type;
      localStorage.setItem("liProjectDehn", JSON.stringify(state.project));
    },
    setName: (state, name) => {
      state.project.name = name;
      localStorage.setItem("liProjectDehn", JSON.stringify(state.project));
    },
    setGrid: (state, grid) => {
      state.project.grid = grid;
      localStorage.setItem("liProjectDehn", JSON.stringify(state.project));
    },
    setMainDistributor: (state, distributor) => {
      state.project.main.articleNo = distributor.articleNo;
      state.project.main.name = distributor.name;
      state.project.main.alertText = distributor.alertText;
      localStorage.setItem("liProjectDehn", JSON.stringify(state.project));
    },
    setDivider: (state, divider) => {
      state.project.dividers = [divider, ...state.project.dividers];
      localStorage.setItem("liProjectDehn", JSON.stringify(state.project));
    },
    removeDivider: (state, index) => {
      state.project.dividers.splice(index, 1);
      localStorage.setItem("liProjectDehn", JSON.stringify(state.project));
    },
    removeCritEndGroup: (state, index) => {
      state.project.critEndGroups.splice(index, 1);
      localStorage.setItem("liProjectDehn", JSON.stringify(state.project));
    },
    setCritEndGroup: (state, product) => {
      state.project.critEndGroups = [product, ...state.project.critEndGroups];
      localStorage.setItem("liProjectDehn", JSON.stringify(state.project));
    },
    // NEW
    setNewProject(state) {
      state.newProjectStore = {
        type: "",
        external: null,
        lpl: null,
        grid: null,
        itemType: null,
        cableLength: "",
        location:"",
        lplZone: "",
        ampere: "",
        shortCircuitResistance: "",
        preAmp: "",
        phase: "",
        product: null,
      };
      localStorage.setItem("liSelectieMatrixDehn", JSON.stringify(state.newProjectStore));
    },
    setMainDivider: (state, divider) => {
      state.newProjectStore.mainDivider = divider;
      localStorage.setItem("liSelectieMatrixDehn", JSON.stringify(state.newProjectStore));
    },
    setTypeNew: (state, type) => {
      state.newProjectStore.type = type;
      localStorage.setItem("liSelectieMatrixDehn", JSON.stringify(state.newProjectStore));
    },
    setItemTypeNew: (state, type) => {
      state.newProjectStore.itemType = type;
      localStorage.setItem("liSelectieMatrixDehn", JSON.stringify(state.newProjectStore));
    },
    setNameNew: (state, name) => {
      state.newProjectStore.name = name;
      localStorage.setItem("liSelectieMatrixDehn", JSON.stringify(state.newProjectStore));
    },
    setExternalNew: (state, external) => {
      state.newProjectStore.external = external;
      localStorage.setItem("liSelectieMatrixDehn", JSON.stringify(state.newProjectStore));
    },
    setLocationNew: (state, external) => {
      state.newProjectStore.location = external;
      localStorage.setItem("liSelectieMatrixDehn", JSON.stringify(state.newProjectStore));
    },
    setLPLNew: (state, lpl) => {
      state.newProjectStore.lpl = lpl;
      localStorage.setItem("liSelectieMatrixDehn", JSON.stringify(state.newProjectStore));
    },
    setGridNew: (state, grid) => {
      state.newProjectStore.grid = grid;
      localStorage.setItem("liSelectieMatrixDehn", JSON.stringify(state.newProjectStore));
    },
    restoreProjectNew: (state, obj) => {
      state.newProjectStore = obj.project;
    },
    updateLocalStorageNew(state) {
      localStorage.setItem("liSelectieMatrixDehn", JSON.stringify(state.newProjectStore));
    },
    // NEW
  },
  getters: {
    getCollection: (state) => (collectionName) => {
      if (state.collections[collectionName]) {
        return state.collections[collectionName];
      } else {
        return null;
      }
    },
    getProjectDialog: (state) => {
      return state.projectDialog;
    },
    getProject: (state) => {
      return state.project;
    },
    getProjectName: (state) => {
      return state.project.name;
    },
    getProjectMainDistributor: (state) => {
      return state.project.main;
    },
    getProjectDivider: (state) => {
      return state.project.dividers;
    },
    getQuestionsR: (state) => {
      return state.questions["00r"];
    },
    getQuestionsI: (state) => {
      return state.questions["01i"];
    },
    getQuestionsU: (state) => {
      return state.questions["02u"];
    },
    getQuestions: (state) => {
      return state.questions;
    },
    // NEW
    getNewProject: (state) => {
      return state.newProjectStore;
    },
  },
};
