<template>
  <v-footer padless fixed :class="$route.name == 'Home' ? 'px-5 pb-5' : 'px-1 pb-1'" style="background: transparent">
    <div class="liFooter">
      {{ new Date().getFullYear() }} {{ appConfig.footer.text }} |
      <a :href="appConfig.footer.legal.url" target="_blank">
        {{ appConfig.footer.legal.name }}
      </a>
    </div>
  </v-footer>
</template>

<script>
export default {
  title: "",
  name: "Footer",
  data() {
    return {
      fromRoute: null,
      error: false,
    };
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
