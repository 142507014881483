const API_URL = process.env.VUE_APP_API_URL;
const API_CLIENT = process.env.VUE_APP_API_CLIENT;
const API_SECRET = process.env.VUE_APP_API_SECRET;
const TOKEN = Buffer.from(`${API_CLIENT}:${API_SECRET}`, 'utf8').toString('base64');

import axios from "axios";
import store from "../store";
import router from "../router";

const guest = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "X-Requested-With": "XMLHttpRequest",
    'Authorization': `Basic ${TOKEN}`
  },
});

const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "X-Requested-With": "XMLHttpRequest",
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("liPublicSession");
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }
  return config;
});

api.interceptors.response.use((response) => {
  return response;
}, (error)=> {
  // Do something with response error
  if (error.response.status === 401) {
      console.warn('unauthorized, logging out ...');
      store.dispatch("authpublic/logout");
      router.replace('/login');
  }
  else {
    return Promise.reject(error.response);
  }
});


export {
  guest,
  api
};