<template>
  <draggable :list.sync="realValue" :move="checkMove" class="liDraggableList" tag="ul" :group="`items-${calcDepth}`" ghost-class="ghost" v-bind="{ swapThreshold: 1, animation: 150 }" @start="start" @end="end" @change="change">
    <transition-group name="fade">
      <li
        v-for="(item, index) in realValue"
        :key="index"
        class="liDraggableListItem"
        :class="{
          note: item.type == 0,
          hasChildren: item._items.length > 0,
          even: index % 2 == 0,
        }"
      >
        <div
          class="inner"
          :class="{
            active: selected != null && selected.identifier == item.identifier,
            subdivider: item.type == 1,
            criticalEndGroup: item.type == 2,
            undetermined: item.type == '',
            'has-error': rule_ItemNeedsCheck(item, parent),
          }"
          @click.stop="select(item, parent, calcDepth)"
        >
          <!-- 'text-primary font-italic': Number(item.ampere) > Number(parent.ampere), -->
          <div class="content d-flex" v-if="item != undefined">
            <div class="liDescription" :style="`width: calc(30% - ${depth * 23 + 25}px)`">
              <div class="liTypeIcon" v-if="item.type == 1 || item.type == 2">
                <v-img :src="appConfig.texts.project.types[item.type].icon" width="30px" />
              </div>
              <div v-if="item.type == ''" dark class="mr-2 liTypeIcon liChipIcon d-flex">
                <v-icon color="grey-darken-4" class="mx-auto">mdi-help-circle-outline</v-icon>
              </div>
              <!-- PRODUCT ERROR -->
              <v-chip v-if="item.noProductReason != '' && !rule_ProductNotRequired(item)" dark class="mr-2 rounded-circle liChipIcon liIndicator noProductReason" outlined color="error">
                <v-icon color="error">mdi-flash-triangle</v-icon>
              </v-chip>
              <v-chip v-if="rule_ItemNeedsCheck(item, parent)" dark class="mr-2 rounded-circle liChipIcon liIndicator noProductReason" outlined color="error">
                <v-icon color="error">mdi-flash-triangle</v-icon>
              </v-chip>
              <!-- MULTIPLE PRODUCTS AVAILABLE -->
              <v-chip v-if="'productOptions' in item && item.productOptions != '' && !rule_ProductNotRequired(item)" dark class="mr-2 rounded-circle liChipIcon liIndicator multipleProduct" outlined color="info">
                <v-icon color="info">mdi-flash-triangle</v-icon>
              </v-chip>
              <!-- PROTECTED -->
              <v-chip v-if="rule_ProductNotRequired(item)" dark class="white mr-2 rounded-circle liChipIcon liIndicator" outlined color="success">
                <v-icon color="success">mdi-shield-check</v-icon>
              </v-chip>
              <!-- NOT PROTECTED AND NO PRODUCT -->
              <v-chip v-if="!rule_ProductNotRequired(item) && item.product == null && item.product == ''" max-height="30" max-width="30" dark class="mr-2 rounded-circle liChipIcon liIndicator" outlined color="warning">
                <v-icon color="warning">mdi-alert</v-icon>
              </v-chip>
              <!-- NAME -->
              <span class="text-s my-auto" v-text="item.name" />
              <!-- <span class="text-s my-auto" v-text="item.name" :class="{ 'text-primary': rule_ItemNeedsCheck(item, parent) }" /> -->
            </div>
            <div style="width: 50px">
              <!-- EXTERNAL -->
              <v-chip class="liProduct2x" v-if="item.product != null && item.product != '' && project[0].external == 1 && (item.external == 1 || item.external == '1') && !rule_ProductNotRequired(item)" style="" color="blue">
                <b> 2x </b>
              </v-chip>
            </div>
            <!-- <div style="width: calc(40% - 25px)"> -->
              <div :style="`width: calc(40% + ${depth * 23 - 25}px)`">
              <div class="d-flex" style="height: 100%" v-if="rule_ProductNotRequired(item)">
                <i class="text-s my-auto">{{ appConfig.texts.project.itemIsSecure }}</i>
              </div>
              <!-- PRODUCT -->
              <div class="liProductChip" v-if="item.product != null && item.product != '' && !rule_ProductNotRequired(item)">
                <v-img :src="`${apiPublicUrl}${product(item.product).thumbnailpath}`" max-height="30" max-width="30" class="mr-2" />
                <div class="d-flex w-100 flex-column" style="line-height: 1.2 !important">
                  <div style="font-size: 11px; margin: 2px 10px 0px 0px">
                    {{ product(item.product).name }} <b class="ml-1 mr-3"> {{ product(item.product).article_number }} </b><br />
                  </div>
                  <div class="technical text-dark d-flex align-center" v-if="item.cableLength == '>10'" style="margin: 2px 0 0 -2px; font-size: 11px">
                    <template v-if="item.ampere != ''">
                      <v-tooltip top v-if="item.preAmp == 'ja'">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon x-small v-bind="attrs" v-on="on" style="font-size: 11px; margin-right: 3px"> mdi-fuse </v-icon>
                        </template>
                        <span style="font-size: 11px" v-html="appConfig.texts.project.form.preAmp.label" />
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="mx-1" v-if="item.ampere != ''"> {{ item.ampere == 24 ? "&#x3c; 25 " : item.ampere }}<span class="grey--text text-xs">A</span> </span>
                        </template>
                        <span style="font-size: 11px" v-html="appConfig.texts.project.form.amp.label" />
                      </v-tooltip>
                      <template v-if="item.shortCircuitResistance != '' && String(item.shortCircuitResistance) != 'nb'">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on" class="mx-1" v-if="item.shortCircuitResistance != ''"> {{ item.shortCircuitResistance }}<span class="grey--text text-xs">kA</span> </span>
                          </template>
                          <span style="font-size: 11px" v-html="appConfig.texts.project.form.shortCircuitResistance.label" />
                        </v-tooltip>
                      </template>
                      <template v-if="item.phase != ''">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on" class="mx-1">
                              {{ item.phase }}
                            </span>
                          </template>
                          <span style="font-size: 11px" v-html="appConfig.texts.project.form.phase.label" />
                        </v-tooltip>
                      </template>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <!-- PRODUCT NOTE  -->
            <div style="width: calc(30% - 30px)">
              <div class="liProductNote" v-if="!lodash.isEmpty(item.productNote) && !rule_ProductNotRequired(item)">
                <span class="note" v-html="item.productNote" />
              </div>
            </div>

            <div class="liActions" style="width: 30px">
              <!-- START  -- / REMOVE ITEM -->
              <v-menu :close-on-content-click="true">
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn icon small v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                        <v-icon small>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <template>
                      <span class="body-3">{{ appConfig.texts.project.tableRemove }}</span>
                    </template>
                  </v-tooltip>
                </template>
                <v-card style="width: 280px; max-width: 280px">
                  <v-list>
                    <v-list-item>
                      <v-list-item-content v-html="appConfig.texts.project.confirmText" />
                    </v-list-item>
                  </v-list>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click.prevent="menu = false">
                      {{ appConfig.texts.project.cancel }}
                    </v-btn>
                    <v-btn color="primary" text @click.prevent="remove(item, index)">
                      {{ appConfig.texts.project.approve }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
              <!-- END  -- / REMOVE ITEM -->
            </div>
          </div>
        </div>
        <!-- NESTED  -->
        <nestedDraggable @addToItem="addToItem" :list="item._items" :selected="selected" @selectedItem="select" @removed="removed" :parent="item" :depth="calcDepth" />
        <template v-if="item.type != 2">
          <v-btn icon x-small class="testAdd" :class="{ single: item._items.length == 0 }" @click.stop="addToItem(item)">
            <v-icon small style="font-size: 13px"> mdi-plus </v-icon>
          </v-btn>
        </template>
      </li>
    </transition-group>
  </draggable>
</template>
<script>
import _ from "lodash";
const API_URL = process.env.VUE_APP_API_URL;
import draggable from "vuedraggable";
export default {
  name: "nestedDraggable",
  data() {
    return {
      apiPublicUrl: API_URL,
      lodash: _,
    };
  },
  components: {
    draggable,
  },
  computed: {
    project() {
      return this.$store.getters["liDehnProject/getCollection"](`project_${this.$route.params.identifier}`);
    },
    calcDepth() {
      return this.depth + 1;
    },
    realValue: {
      get() {
        return this.value ? this.value /* input = v-model */ : this.list /* input = !v-model */;
      },
      set(value) {
        return value;
      },
    },
  },
  methods: {
    product(identifier) {
      if (this.products != null) {
        return this.products.find((item) => {
          return item.identifier == identifier;
        });
      } else {
        return "";
      }
    },
    addToItem(item) {
      this.$emit("addToItem", item);
    },
    select(item, parent, depth) {
      this.$emit("selectedItem", item, parent, depth);
    },
    remove(item, index) {
      this.realValue.splice(index, 1);
      this.$emit("removed", item);
    },
    removed(item) {
      this.$emit("removed", item);
    },
    change(e) {
      // if (e.added) {
      //   console.log(e.added);
      //   // let item = this.items.find(item => item.id == "clone");
      //   // this.$set(item ,'id', this.generateUUID());
      // }
      if (e.moved) {
        console.debug("From index: ", e.moved.oldIndex);
        // console.log("To index: ", e.moved.newIndex);
      }
    },
    end(e) {
      console.debug("end", e);
      this.dragging = false;
    },
    start(e) {
      console.debug("start", e);
      this.dragging = true;
    },
    checkMove(e) {
      window.console.debug("Future index: " + e.draggedContext.futureIndex);
    },
    // RULES
    rule_ProductNotRequired(selectedItem) {
      return selectedItem.cableLength == "<10" ? true : false;
    },
    rule_ItemNeedsCheck(selectedItem, parent) {
      return Number(selectedItem.ampere) > Number(parent.ampere) || Number(selectedItem.shortCircuitResistance) > Number(parent.shortCircuitResistance) ? true : false;
    },
  },
  props: {
    value: {
      required: false,
      type: Array,
      default: null,
    },
    list: {
      required: false,
      type: Array,
      default: null,
    },
    selected: null,
    parent: {
      required: true,
      type: Object,
      default: null,
    },
    depth: {
      required: true,
      type: Number,
      default: 0,
    },
  },
};
</script>
<style lang="scss">
.liDraggableListItem {
  .has-error {
    background-color: rgba(227, 0, 11, 0.2) !important;
  }
  .active {
    &.has-error {
      background-color: rgba(73, 80, 87, 0.3)!important;
    }
  }
  .content {
    .showMe {
      opacity: 0;
    }
  }
  &:hover {
    .content {
      .showMe {
        opacity: 1;
      }
    }
  }
}
</style>
