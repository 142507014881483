<template>
  <v-dialog v-model="showModal" :width="isMobileApp ? '100%':'80%' "  scrollable max-width="1400" min-height="1400" >
    <v-card v-if="infoItem != null">
      <div class="pa-3 d-flex w-100">
        <span v-html="infoItem.title" />
        <v-btn icon small class="ml-auto mr-0 mt-0" @click.prevent="close()"><v-icon> mdi-close-circle-outline </v-icon></v-btn>
      </div>
      <v-card-text class="pt-3 pb-10 bg-white infoModal">
        <v-img v-if="!isMobileApp && infoItem.image != ''" :src="`${apiPublicUrl}${infoItem.image}`" width="100%" class="primary darken-4 mb-3"/>
        <v-img v-else-if="isMobileApp && infoItem.image_mobile != ''" :src="`${apiPublicUrl}${infoItem.image_mobile}`" width="100%" class="primary darken-4 mb-3"/>
        <v-img v-else-if="isMobileApp && infoItem.image_mobile == '' && infoItem.image != ''" :src="`${apiPublicUrl}${infoItem.image}`" width="100%" class="primary darken-4 mb-3"/>
        <div v-html="infoItem.text"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
const API_URL = process.env.VUE_APP_API_URL;
export default {
  name: "liInfoModal",
  data() {
    return {
      apiPublicUrl: API_URL,
      showModal: false,
      infoItem: null,
    };
  },
  methods: {
    open(_identifier) {
      this.infoItem = this.info.find((item) => {
        return item.unique_identifier == _identifier;
      });
      setTimeout(() => {
        this.showModal = true;
      }, 25);
    },
    close() {
      this.showModal = false;
      setTimeout(() => {
        this.infoItem = null;
      }, 100);
    },
  },
};
</script>
