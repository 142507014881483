<template>
  <v-sheet v-if="selected != null" elevation="0" class="body-3 mx-3 bg-medium" color="light" style="position: relative; margin-bottom: 22px">
    <v-form ref="editItem" v-model="valid" lazy-validation>
      <v-sheet v-if="selected != null" class="liEditItem" :class="{ note: selected.type == 0 }" color="transparent" style="position: relative">
        <!-- HEADER -->
        <v-sheet elevation="0" class="pa-0 mb-3" color="transparent">
          <div class="d-flex" :style="selected.type != 0 ? '' : ''">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <!-- :color="selected.type != 0 ? 'grey lighten-2' : 'transparent'" -->
                <v-btn v-bind="attrs" v-on="on" @click.prevent="selected = null" elevation="0" light fab small absolute top right color="primary">
                  <v-icon small>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>{{ appConfig.texts.project.closeTab }}</span>
            </v-tooltip>
          </div>
        </v-sheet>
        <!-- /. HEADER -->
        <div class="px-3">
          <perfect-scrollbar>
            <div class="inner">
              <!-- TITLE -->
              <div class="w-100 white pt-3 px-3" :class="{ transparent: selected.type == 0 }">
                <label>{{ appConfig.texts.project.form.name.label }}</label>
                <v-text-field dense validate-on-blur v-model="selected.name" :placeholder="appConfig.texts.project.form.name.placeholder" />
              </div>
              <template v-if="selected.type == 9000">
                <!-- MAIN DIVIDER -->
                <div class="w-100 white pt-3 px-3 mt-3">
                  <label>{{ appConfig.texts.project.form.amp.label }} </label>
                  <v-autocomplete
                    dense
                    :placeholder="appConfig.texts.project.form.amp.placeholder"
                    :items="appConfig.texts.project.form.amp.options[`all`]"
                    v-model="selected.ampere"
                    item-value="value"
                    item-text="name"
                    class="pr-3 pt-1"
                    @input="checkMainDivider"
                  />
                </div>
                <div class="w-100 white pt-3 px-3 mt-3">
                  <label>{{ appConfig.texts.project.form.shortCircuitResistance.label }} </label>
                  <v-select
                    dense
                    :placeholder="appConfig.texts.project.form.shortCircuitResistance.placeholder"
                    :items="appConfig.texts.project.form.shortCircuitResistance.options"
                    v-model="selected.shortCircuitResistance"
                    item-value="value"
                    item-text="name"
                    class="pr-3 pt-1"
                    @input="checkMainDivider"
                  />
                </div>
                <div class="w-100 white pt-3 px-3 mt-3">
                  <label>{{ appConfig.texts.project.form.preAmp.label }}</label>
                  <v-select
                    dense
                    :placeholder="appConfig.texts.project.form.preAmp.placeholder"
                    :items="appConfig.texts.project.form.preAmp.options"
                    v-model="selected.preAmp"
                    item-value="value"
                    item-text="name"
                    class="pr-3 pt-1"
                    @input="checkMainDivider"
                  />
                </div>
              </template>
              <template v-else>
                <!-- SUBDIVIDER OR CRITICAL END GROUP -->
                <!-- EXTERNAL -->
                <div class="w-100 white pt-3 px-3 mt-3" v-if="selected.type != 0">
                  <label v-html="appConfig.texts.project.form.external.label" />
                  <v-radio-group dense v-model="selected.external" row :rules="defaultRules" required>
                    <template v-for="(option, index) in appConfig.texts.project.form.external.options">
                      <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10" @input="checkDivider(selected)"></v-radio>
                    </template>
                  </v-radio-group>
                </div>
                <!-- CABLE LENGTH -->
                <div class="w-100 white pt-3 px-3 mt-3" v-if="selected.type != 0 && selected.type">
                  <label v-html="appConfig.texts.project.form.cableLength.label" />
                  <v-radio-group dense v-model="selected.cableLength" row :rules="defaultRules" required @change="setCableLength(selected)">
                    <template v-for="(option, index) in appConfig.texts.project.form.cableLength.options">
                      <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10"></v-radio>
                    </template>
                  </v-radio-group>
                </div>
                <!-- NO PRODUCT REQUIRED -->
                <div v-if="selected.type != 0 && selected.cableLength == '<10' && selected.external == '0'" class="mt-3 w-100 d-flex px-3 pt-3 white">
                  <v-alert dense text type="success" icon="mdi-shield-check" class="rounded-md"><span v-html="appConfig.texts.project.form.cableLength.explaination" /></v-alert>
                </div>
                <!-- PRODUCT -->
                <template v-if="selected.external == '1' || selected.cableLength == '>10'">
                  <!-- GRID -->
                  <div class="w-100 white pt-3 px-3 mt-3" v-if="project[0].grid == 'TN-C'">
                    <label v-html="appConfig.texts.project.form.grid.label" />
                    <v-radio-group dense v-model="selected.grid" row :rules="defaultRules" required @change="setGrid(selected)">
                      <template v-for="(option, index) in appConfig.texts.project.form.grid.options">
                        <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10"></v-radio>
                      </template>
                    </v-radio-group>
                  </div>
                  <!-- AMPERE -->
                  <div class="w-100 white pt-3 px-3 mt-3">
                    <label>{{ appConfig.texts.project.form.amp.label }} </label>
                    <v-autocomplete
                      dense
                      :placeholder="appConfig.texts.project.form.amp.placeholder"
                      :items="appConfig.texts.project.form.amp.options[`all`]"
                      v-model="selected.ampere"
                      item-value="value"
                      item-text="name"
                      @input="checkDivider(selected)"
                    />
                  </div>
                  <!-- KORTSLUITVASHEID -->
                  <div class="w-100 white pt-3 px-3 mt-3">
                    <label>{{ appConfig.texts.project.form.shortCircuitResistance.label }} </label>
                    <v-autocomplete
                      dense
                      required
                      :placeholder="appConfig.texts.project.form.shortCircuitResistance.placeholder"
                      :items="appConfig.texts.project.form.shortCircuitResistance.options"
                      v-model="selected.shortCircuitResistance"
                      item-value="value"
                      item-text="name"
                      class="pr-3 pt-1"
                      @input="checkDivider(selected)"
                    />
                  </div>
                  <!-- PHASE -->
                  <div class="w-100 white pt-3 px-3 mt-3">
                    <label>{{ appConfig.texts.project.form.phase.label }}</label>
                    <v-select
                      dense
                      required
                      :placeholder="appConfig.texts.project.form.phase.placeholder"
                      :items="appConfig.texts.project.form.phase.options"
                      v-model="selected.phase"
                      item-value="value"
                      item-text="name"
                      @input="checkDivider(selected)"
                    ></v-select>
                  </div>
                  <!-- INTEGREATE FUSE -->
                  <div class="w-100 white pt-3 px-3 mt-3">
                    <label>{{ appConfig.texts.project.form.preAmp.label }}</label>
                    <v-select
                      dense
                      :required="!selected.preAmp"
                      :placeholder="appConfig.texts.project.form.preAmp.placeholder"
                      :items="appConfig.texts.project.form.preAmp.options"
                      v-model="selected.preAmp"
                      item-value="value"
                      item-text="name"
                      @input="checkDivider(selected)"
                    ></v-select>
                  </div>
                </template>
              </template>
              <!-- PRODUCT -->
              <div class="mt-3 w-100 px-3 pt-3 white" v-if="selected.type == 9000 || selected.external == '1' || selected.cableLength == '>10'">
                <label v-html="appConfig.texts.project.form.selectProduct.label" />
                <v-autocomplete
                  v-model="selected.product"
                  :items="products"
                  chips
                  dense
                  :filter="searchProduct"
                  color="blue-grey lighten-2"
                  item-value="identifier"
                  item-text="name"
                  :placeholder="appConfig.texts.project.form.selectProduct.placeholder"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      style="height: 45px; padding-right: 25px; margin-bottom: 5px; border-radius: 45px"
                      class="body-3 grey lighten-3"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click:close="selected.product = null"
                    >
                      <img :src="`${apiPublicUrl}${data.item.thumbnailpath}`" style="width: 40px" class="mr-3" />
                      {{ data.item.name }} <span class="mx-3">{{ data.item.article_number }}</span>
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-avatar>
                      <img :src="`${apiPublicUrl}${data.item.thumbnailpath}`" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      <v-list-item-subtitle v-html="data.item.article_number"></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <!-- NO PRODUCT FOUND -->
                <div v-if="'noProductReason' in selected && selected.noProductReason != ''" class="mt-3 w-100 d-flex px-3 pt-3 white">
                  <v-alert dense text type="error" icon="mdi-flash-triangle" class="rounded-md"><span v-html="selected.noProductReason" /></v-alert>
                </div>
                <!-- PRODUCT NOTE-->
                <div v-if="'productNote' in selected && selected.productNote != ''" class="mt-3 w-100 d-flex px-3 pt-3 white">
                  <v-alert dense text type="warning" icon="mdi-flash-triangle" class="rounded-md"><span v-html="selected.productNote" /></v-alert>
                </div>
              </div>
              <!-- DESCRIPTION -->
              <div class="w-100 white pt-3 px-3 mt-3" :class="{ transparent: selected.type == 0 }">
                <label>
                  {{ appConfig.texts.project.form.description.label }}
                </label>
                <v-textarea dense validate-on-blur v-model="selected.description" rows="2" :placeholder="appConfig.texts.project.form.description.placeholder"></v-textarea>
              </div>
            </div>
          </perfect-scrollbar>
        </div>
      </v-sheet>
    </v-form>
  </v-sheet>
</template>

<script>
export default {
  title: "",
  name: "editItem",
  data() {
    return {
        valid:true,
    };
  },
  props: {
    selected: {
      type: Object,
      required: true,
      default: null,
    },
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
