<template>
  <v-dialog v-model="showDialog" :max-width="options.width" :style="{ zIndex: options.zIndex }">
    <v-card :color="options.color">
      <v-card-text class="pa-3 bg-white">
        <div class="d-flex w-100">
          <label v-html="appConfig.texts.project.form.grid.labelMD" />
        </div>
      </v-card-text>
      <v-card-actions class="pt-3 bg-white d-flex justify-space-between">
        
        <v-btn outlined style="width:calc(50% - 15px); margin-right:15px; text-transform: unset; letter-spacing: unset;" @click.native="agree(appConfig.texts.project.form.grid.options[0].value)">{{ appConfig.texts.project.form.grid.options[0].name }}</v-btn>
        <v-btn outlined style="width:calc(50% - 15px); margin-left:15px; text-transform: unset; letter-spacing: unset;" @click.native="agree(appConfig.texts.project.form.grid.options[1].value)" >{{ appConfig.texts.project.form.grid.options[1].name }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "liConfirmGrid",
  data() {
    return {
      showDialog: false,
      resolve: null,
      reject: null,
      options: {
        width: 490,
        zIndex: 9000,
      },
    };
  },
  methods: {
    open() {
      this.showDialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree(_value) {
      this.showDialog = false;
      this.resolve(_value);
    },
   
  },
};
</script>
