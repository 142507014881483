<template>
  <svg
    version="1.1"
    id="logo"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 125 50"
    class="dehnLogo"
  >
    <rect
      fill="none"
      width="125"
      height="50"
    />
    <g>
      <polygon
        fill="#ffffff"
        points="84.8,35.5 86.5,26.7 112.5,20.8 	"
      />
      <polygon
        fill="#ffffff"
        points="40.7,14.5 39,23.3 12.5,29.4 	"
      />
      <polygon
        fill="#ffffff"
        points="51.2,34.6 58.8,32.8 59.4,29.9 55.6,30.8 56.2,27.9 59.9,27 60.5,24.1 56.7,25 57.3,22.1 61.1,21.2 61.6,18.3 54.1,20.1"
      />
      <polygon
        fill="#ffffff"
        points="61.3,32.3 65.1,31.4 66.3,25.6 68.8,25 67.7,30.8 71.4,29.9 74.3,15.4 70.5,16.3 69.4,22.1 66.8,22.7 68,16.9 64.2,17.7"
      />
      <polygon
        fill="#ffffff"
        points="74,29.3 77.8,28.5 79.1,21.3 80.3,27.9 84.1,27 86.9,12.5 83.1,13.4 81.7,20.4 80.6,14 76.8,14.8"
      />
      <g>
        <path
          fill="#ffffff"
          d="M46.9,25.7c0.2-1-0.2-1.2-1-1L44.7,25L43,33.7l1.3-0.3c0.8-0.2,1.3-0.3,1.5-1.3L46.9,25.7z M47.3,21.6 c2.5-0.6,3.8,1,3.5,2.9L49.1,33c-0.4,2.2-1.7,2.5-5.1,3.3l-5.5,1.3L41.4,23L47.3,21.6z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  title: "",
  name: "Logo",
  data() {
    return {
    };
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
