import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Projects from "../views/Projects.vue";
import Project from "../views/Project.vue";
import store from "../store";

import Login from "../views/authentication/Login";
import Logout from "../views/authentication/Logout";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path: "/",
    name: "Home",
    component: process.env.VUE_APP_INSTALL_ENV == 'APP' ? Projects : Home,
    meta: {
      requiresAuth: process.env.VUE_APP_INSTALL_ENV == 'APP' ? true : false,
    },
  },
  
  {
    path: "/product-selector",
    name: "Product-selector",
    component: () => import(/* webpackChunkName: "Question" */ "../views/Products.vue"),
  },
  {
    path: "/selectie-matrix",
    name: "SelectieMatrix",
    component: () => import(/* webpackChunkName: "Question" */ "../views/SelectieMatrix.vue"),
  },
  {
    path: "/projects",
    name: "Projects",
    component: Projects,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/project/:identifier",
    name: "Project",
    component: Project,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "*",
    name: "404",
    component: () => import(/* webpackChunkName: "Question" */ "../views/PageNotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  // if (store.getters["liDehnProject/getProject"] === null && !localStorage.getItem("liProjectDehn") && !localStorage.getItem("liProjectDehnCustom")) {
  //   store.dispatch("liDehnProject/SET_PROJECT");
  //   next({ name: "Home" });
  // } else if (store.getters["liDehnProject/getProject"] === null && to.name != "Projects" && to.name != "Project") {
  //   store.dispatch("liDehnProject/RESTORE_PROJECT", {
  //     project: JSON.parse(localStorage.getItem("liProjectDehn")),
  //     custom: JSON.parse(localStorage.getItem("liProjectDehnCustom")),
  //   });
  // }

  if (store.getters["liDehnProject/getNewProject"] === null && !localStorage.getItem("liSelectieMatrixDehn")) {
    store.dispatch("liDehnProject/SET_NEW_PROJECT");
    // next({ name: "ProductSelectorMatrix" });
  } else if (store.getters["liDehnProject/getNewProject"] === null) {
    store.dispatch("liDehnProject/RESTORE_PROJECT_NEW", {
      project: JSON.parse(localStorage.getItem("liSelectieMatrixDehn")),
    });
  }
  next();
});

export default router;
