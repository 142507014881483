<template>
  <v-app-bar fixed dark elevation="0" color="primary">
    <v-toolbar-title>
      <v-btn  text elevation="0" class="li-dehn-logo" @click.prevent="triggerHome"  >
        <liLogo />
      </v-btn>
    </v-toolbar-title>
    <v-spacer/>
    <v-btn plain small style="text-transform: unset" @click.prevent="triggerHome" v-if="instalLEnv != 'APP'">
      <v-icon small> mdi-home</v-icon>
    </v-btn>
    <template v-for="(btn, index) in appConfig.texts.app.nav[instalLEnv]">
      <v-btn :key="index" rounded pill elevation="0" color="white" class="liHeaderBtn text--primary mr-2" style="text-transform: unset" :to="btn.uri">
        {{ btn.label }}
      </v-btn>
    </template>
    <div style="position: absolute; top:0; right:0; padding:5px; font-size: 8px;">
    v. {{ appVersion }}
    </div>
  </v-app-bar>
</template>

<script>
const INSTALL_ENV = process.env.VUE_APP_INSTALL_ENV;
const APP_VERSION = process.env.VUE_APP_VERSION;
export default {
  title: "",
  name: "Logo",
  data() {
    return {
      instalLEnv: INSTALL_ENV,
      appVersion: APP_VERSION
    };
  },
  computed: {
    hasProject() {
      if (this.project != null && this.project.type != undefined && this.project.type != "") {
        return false;
      } else {
        return true;
      }
    },
    computedProjectType() {
      if (this.project != null && this.project.type != undefined && this.project.type != "") {
        return this.appConfig.types.find((type) => type.identifier === this.project.type).name;
      } else {
        return false;
      }
    },
    computedProjectGrid() {
      if (this.project.type != "00r" && this.project != null && this.project.grid != undefined && this.project.grid != "" && this.project.grid != "-") {
        // return false;
        return this.appConfig.grid.find((grid) => grid.identifier === this.project.grid).name;
      } else if (this.project.type == "00r" && this.project != null && this.project.grid == "-") {
        return this.appConfig.grid.find((grid) => grid.identifier === "TT").name;
      } else {
        return false;
      }
    },
  },
  mounted() {},
  methods: {},
};
</script>
