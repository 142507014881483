<template>
  <!-- ### √ DOWNLOAD PDF -->
  <div class="liDownloadPDF" v-if="projectLocal != null && projectLocal.mainDivider != null && products != null">
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="900"
      :filename="`${appConfig.texts.result.pdf.fileName}-${projectLocal.name}-${getCurrentDate()}` | slugify"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section class="arial pdf-file" slot="pdf-content">
        <section class="pdf-item header">
          <div class="top">
            <svg version="1.1" id="logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 150 50" class="dehnLogoPdf" width="200">
              <g>
                <polygon fill="#e3000b" points="84.8,35.5 86.5,26.7 112.5,20.8 	" />
                <polygon fill="#e3000b" points="40.7,14.5 39,23.3 12.5,29.4 	" />
                <polygon fill="#e3000b" points="51.2,34.6 58.8,32.8 59.4,29.9 55.6,30.8 56.2,27.9 59.9,27 60.5,24.1 56.7,25 57.3,22.1 61.1,21.2 61.6,18.3 54.1,20.1" />
                <polygon fill="#e3000b" points="61.3,32.3 65.1,31.4 66.3,25.6 68.8,25 67.7,30.8 71.4,29.9 74.3,15.4 70.5,16.3 69.4,22.1 66.8,22.7 68,16.9 64.2,17.7" />
                <polygon fill="#e3000b" points="74,29.3 77.8,28.5 79.1,21.3 80.3,27.9 84.1,27 86.9,12.5 83.1,13.4 81.7,20.4 80.6,14 76.8,14.8" />
                <g>
                  <path
                    fill="#e3000b"
                    d="M46.9,25.7c0.2-1-0.2-1.2-1-1L44.7,25L43,33.7l1.3-0.3c0.8-0.2,1.3-0.3,1.5-1.3L46.9,25.7z M47.3,21.6 c2.5-0.6,3.8,1,3.5,2.9L49.1,33c-0.4,2.2-1.7,2.5-5.1,3.3l-5.5,1.3L41.4,23L47.3,21.6z"
                  />
                </g>
              </g>
            </svg>
            <div class="generated-title">
              <h3 class="file-title" v-html="appConfig.texts.result.pdf.fileTitle"></h3>
              <p class="file-subtitle" v-html="appConfig.texts.result.pdf.fileSubTitle"></p>
              <h3 class="projectLocal-title">
                {{ appConfig.texts.result.title }}: {{ projectLocal.name }}
                <br />
              </h3>
            </div>
            <div class="date">
              {{ currentDate }}
            </div>
          </div>
        </section>
        <!-- MAIN DISTRIBUTOR -->
        <section class="pdf-item" v-if="projectLocal">
          <h1>{{ appConfig.texts.products.accordionButtons.mainDivider.title }}</h1>
          <table class="arial pdf-table">
            <thead>
              <tr>
                <th class="text-left py-1" colspan="2">
                  {{ appConfig.texts.result.mainDistributor.tableProduct }}
                </th>
                <th class="py-1"></th>
              </tr>
            </thead>
            <tbody>
              <tr style="height: 50px">
                <td width="80px">
                  <img :src="`${apiPublicUrl}${product(projectLocal.mainDivider.product).thumbnailpath}`" style="width: 40px" crossorigin="anonymous" />
                </td>
                <td width="60%">
                  <div class="liProductName">
                    {{ product(projectLocal.mainDivider.product).name }} <span class="mx-1">{{ product(projectLocal.mainDivider.product).article_number }}</span>
                  </div>
                  <div v-if="'productNote' in projectLocal.mainDivider && projectLocal.mainDivider.productNote != ''" class="alertText">
                    <span v-html="projectLocal.mainDivider.productNote" />
                  </div>
                </td>
                <td class="text-right">
                  <div class="technical">
                    <span class="item"> {{ projectLocal.mainDivider.ampere }} <span class="grey--text">A</span> </span>
                    <span class="item"> {{ projectLocal.mainDivider.shortCircuitResistance }} <span class="grey--text">kArms</span> </span> <br>
                    <span class="item">
                      <span class="grey--text"> {{ appConfig.texts.project.form.preAmp.label }} </span> {{ projectLocal.mainDivider.preAmp }}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <!-- END / - MAIN DISTRIBUTOR -->
        <!--  SUB DIVIDER(S) -->
        <section class="pdf-item" v-if="projectLocal.dividers.length">
          <h1>{{ appConfig.texts.products.accordionButtons.subDivider.title }}</h1>
          <table class="arial pdf-table">
            <thead>
              <tr>
                <th class="text-left py-1" colspan="2">
                  {{ appConfig.texts.result.dividers[projectLocal.type].tableProduct }}
                </th>
                <th class="py-1"></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, n) in projectLocal.dividers">
                <tr :key="n" style="height: 50px">
                  <td width="80px">
                    <img :src="`${apiPublicUrl}${product(item.product).thumbnailpath}`" style="width: 40px" crossorigin="anonymous" />
                  </td>
                  <td width="60%">
                    <div class="liProductName">
                      {{ product(item.product).name }} <span class="mx-1">{{ product(item.product).article_number }}</span>
                    </div>
                    <div v-if="'productNote' in item && item.productNote != ''" class="alertText">
                      <span v-html="item.productNote" />
                    </div>
                  </td>
                  <td class="text-right">
                    <div class="technical">
                      <span class="item"> {{ item.ampere }} <span class="grey--text">A</span> </span>
                      <span class="item"> {{ item.shortCircuitResistance }} <span class="grey--text">kArms</span> </span> <br>
                      <span class="item">
                        <span class="grey--text"> {{ appConfig.texts.project.form.preAmp.label }} </span> {{ item.preAmp }}
                      </span>
                    </div>
                  </td>
                </tr>

                <div :key="`break${n}`" v-if="product.number == 1" class="html2pdf__page-break" />
              </template>
            </tbody>
          </table>
        </section>
        <!-- END / - SUB DIVIDER(S) -->
        <!--  CRIT END GROUPS -->
        <section class="pdf-item" v-if="projectLocal.type != '00r' && projectLocal.critEndGroups.length">
          <h1>{{ appConfig.texts.products.accordionButtons.ceGroup.title }}</h1>
          <table class="arial pdf-table">
            <thead>
              <tr>
                <th class="text-left py-1" colspan="2">
                  {{ appConfig.texts.result.critEndGroups.tableProduct }}
                </th>
                <th class="py-1"></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, n) in projectLocal.critEndGroups">
                <tr :key="n" style="height: 50px">
                  <td width="80px">
                    <img :src="`${apiPublicUrl}${product(item.product).thumbnailpath}`" style="width: 40px" crossorigin="anonymous" />
                  </td>
                  <td width="60%">
                    <div class="liProductName">
                      {{ product(item.product).name }} <span class="mx-1">{{ product(item.product).article_number }}</span>
                    </div>
                    <div v-if="'productNote' in item && item.productNote != ''" class="alertText">
                      <span v-html="item.productNote" />
                    </div>
                  </td>
                  <td class="text-right">
                    <div class="technical">
                      <span class="item"> {{ item.ampere }} <span class="grey--text">A</span> </span>
                      <span class="item"> {{ item.shortCircuitResistance }} <span class="grey--text">kArms</span> </span> <br>
                      <span class="item">
                        <span class="grey--text"> {{ appConfig.texts.project.form.preAmp.label }} </span> {{ item.preAmp }}
                      </span>
                    </div>
                  </td>
                </tr>

                <div :key="`break${n}`" v-if="product.number == 1" class="html2pdf__page-break" />
              </template>
            </tbody>
          </table>
          <!-- END / -  CRIT END GROUPS -->
        </section>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
const API_URL = process.env.VUE_APP_API_URL;
export default {
  title: "",
  name: "liDownloadPDF",
  data() {
    return {
      apiPublicUrl: API_URL,
      currentDate: null,
    };
  },
  components: {
    VueHtml2pdf,
  },
  props: {
    projectLocal: null,
  },
  computed: {},
  mounted() {
    setTimeout(() => {
     
      this.$nextTick(() => {
        this.$refs.html2Pdf.generatePdf();
      });
    }, 500);
  },
  methods: {
    getCurrentDate() {
      const dateObj = new Date();
      const month = this.appConfig.texts.monthNames[dateObj.getMonth()];
      const day = String(dateObj.getDate()).padStart(2, "0");
      const year = dateObj.getFullYear();

      return day + "\n" + month + "\n" + year;
    },
    // /*
    //  Generate Report using refs and calling the
    //  refs function generatePdf()
    //  */
    // generateReport() {
    //   this.loading = true;
    //   setTimeout(() => {
    //     this.currentNumber = 7;
    //     this.currentPage = 1;
    //     let items = [
    //       {
    //         dividers: this.projectLocal.dividers,
    //         critEndGroups: this.projectLocal.critEndGroups,
    //       },
    //     ];
    //     items.forEach((item) => {
    //       Object.values(item).forEach((val) => {
    //         val.forEach((element) => {
    //           element.number = this.currentNumber;
    //           if (this.currentNumber > 11) {
    //             this.currentNumber = 1;
    //             this.currentPage++;
    //           } else if (this.currentNumber <= 11) {
    //             this.currentNumber++;
    //           }
    //         });
    //       });
    //     });
    //     this.calculatedItems = items;
    //     this.$nextTick(() => {
    //       this.$refs.html2Pdf.generatePdf();
    //     });
    //   }, 500);
    // },
    onProgress(event) {
      // console.log(event);
      if (event == 100) {
        setTimeout(() => {
          this.$emit('done'); 
        }, 1000);
      }
    },
    hasStartedGeneration() {
      console.debug("hasStartedGeneration");
    },
    hasGenerated(event) {
      console.log("hasGenerated", event);
    },
  },
};
</script>

<style lang="scss">
.pdf-file {
  .alertText {
    font-size: 9px !important;
    margin-bottom: 0px;
    color: #e3000b;
    text-align: left !important;
  }
  .technical {
    font-size: 8px !important;
    text-align: left !important;
    .item{
      padding-right:10px;
    }
  }
  background-color: #ffffff;
  padding: 25px;
  float: left;
  width: 100%;
  font-size: 10px;

  .pdf-item {
    float: left;
    width: 100%;
    &:not(.header) {
      margin-top: 25px;
    }
    .alertText {
      font-size: 7px;
    }
  }
  .header {
    .top {
      display: block;
      width: 100%;
      float: left;
    }
    .dehnLogoPdf {
      float: left;
    }
    .generated-title {
      margin: 0px -10px 15px 25px;
      float: left;
    }
    .date {
      margin: 0px 15px 15px 0px;
      float: right;
    }
  }
  p {
    margin: 0 0 15px;
  }
  h1 {
    font-size: 20px;
    margin-bottom: 10px;
    &.project-title {
      border-bottom: 0px;
      display: block;
      float: left;
    }
  }
  .text-left {
    text-align: left;
  }
  .grey--text {
    color: #9e9e9e !important;
  }
  table {
    border-collapse: collapse;
  }
  .pdf-table {
    width: 100%;
    thead {
      tr {
        border-bottom: 1px solid #9e9e9e;
        border-top: 1px solid #9e9e9e;
      }
    }
    th {
      padding: 10px 5px;
    }
    tbody {
      tr {
        td {
          padding: 10px 5px;
        }
      }
    }
  }
}
.arial {
  font-family: Arial;
}
</style>
