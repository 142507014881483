<template>
  <v-container fluid fill-height class="bg-dark">
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-0">
          <v-card-text>
            <b>{{appConfig.texts.logout.title}}</b><br>
            {{ appConfig.texts.logout.textStart}} {{time}} {{ appConfig.texts.logout.textEnd }}
          </v-card-text>
          <v-progress-linear
          :value="(100 / 5 * time)"
          color="primary"
          absolute
          height="7"
        ></v-progress-linear>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
const API_URL = process.env.VUE_APP_API_URL;
export default {
  name: "Logout",
  title: "",
  data() {
    return {
      apiPublicUrl: API_URL,
      isRunning: false,
      time: 5,
      timer: null,
    };
  },
  mounted(){
    this.start();
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
 methods: {
    start(){
      this.isRunning = true;
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.time > 0) {
            this.time--;
          } else {
            clearInterval(this.timer);
            this.reset();
          }
        }, 1000);
      }
    },
    stop() {
      this.isRunning = false;
      clearInterval(this.timer);
      this.timer = null;
      this.start();
      // this.$store.dispatch("auth/logout").then(()=>{
      //   this.$router.push({ name: "Projects" });
      // });
    },
    reset() {
      this.time = 5;
      this.stop();
    },
  },
};
</script>
