<template>
  <v-container class="px-0 py-0" fluid style="min-height: 100vh;">
    <v-img :src="appConfig.images.homeBackground" height="100vh" class="primary darken-4">
      <v-container fluid style="position:absolute; bottom:20vh;">
        <v-row align="center" justify="space-around" class="px-5  li-text-shadow">
          <h1 class="text-h1 white--text mb-5 text-center" v-html="appConfig.texts.home.title" />
        </v-row>
        <v-row align="center" justify="space-around" class="px-5 li-text-shadow">
          <p class="white--text mb-10 text-center" v-html="appConfig.texts.home.subtitle" />
        </v-row>
        <v-row align="center" justify="space-around" v-if="newProjectStore.type == ''">
          <template v-for="(btn,n) in appConfig.texts.home.buttons">
            <v-btn :to="btn.to" :color="btn.color" :class="btn.class" :key="n" class="px-10 bg-white" x-large>
              <b class="text-h5">
                {{btn.text}}
              </b>
            </v-btn>
          </template>
        </v-row>
        <v-row align="center" v-if="newProjectStore.type != ''">
          <div class="mx-auto w-100 w-md-auto px-5">
            <template v-for="(btn,n) in appConfig.texts.home.buttonsActiveProject">
              <v-btn v-if="btn.to != 'reset'" :to="btn.to" :block="isMobileApp" :color="btn.color" :class="btn.class" :key="n" class="mb-4 px-10 mx-0 mx-md-3 bg-white" > 
                <b> {{btn.text}} </b> 
              </v-btn>
              <v-btn v-else @click.prevent="resetProject()" :block="isMobileApp"  :color="btn.color" :class="btn.class" :key="n" class="mb-4 px-10 mx-0 mx-md-3 bg-white" > 
                <b> {{btn.text}} </b> 
              </v-btn>
            </template>
          </div>
        </v-row>
      </v-container>
    </v-img>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  methods:{
    resetProject(){
       let mainDivider = {
        ampere: "",
        grid: "",
        shortCircuitResistance: "",
        preAmp: "",
        product: "",
        productOptions: "",
        productNote: "",
        noProductReason: "",
      };
      this.$store.dispatch("liDehnProject/SET_NEW_PROJECT", mainDivider);
    }
  }
};
</script>
